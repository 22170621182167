// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-shop-js": () => import("./../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lease-js": () => import("./../src/pages/lease.js" /* webpackChunkName: "component---src-pages-lease-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-stores-js": () => import("./../src/pages/stores.js" /* webpackChunkName: "component---src-pages-stores-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */)
}

